import {RemoveRedEyeOutlined} from "@mui/icons-material";
import colors from "../../../assets/theme/base/colors";
import {extractDateFromTimestamp, extractTimeFromTimestamp} from "../../../utils/DateUtils";
import {invertKeyValuePair} from "../../../utils/GenericUtils";
import {Appointment, AppointmentStatus} from "../../../api/appointment/appointment.interface";
import {Filters} from "../../../components/common/MyDataTable/Filter/types";
import {GridColDef, GridRenderCellParams} from "@mui/x-data-grid";
import TrCell from "../../../components/common/MyDataTable/TrCell";
import {Box, Typography} from "@mui/material";
import {
  getTypographyStyles,
  getTrCellStyles,
  getTypeOfVisitStyles,
  recordBadgeStyles,
  statusTextStyles,
} from "./styles";
import {APPOINTMENT_TYPES} from "../../../constants/Values";
import {AppointmentRow} from "./types";
import MicIcon from "../../../assets/images/icons/MicIcon";
import blur from "../../../assets/theme/base/blur";

const TypeOfVisitBackground = {
  [APPOINTMENT_TYPES.EMR]: colors.badgeColors.lightBlue.background,
  [APPOINTMENT_TYPES.ADHOC]: colors.badgeColors.lightYellow.background,
  [APPOINTMENT_TYPES.ADHOC_EMR]: colors.badgeColors.lightYellow.background,
};

enum APPOINTMENT_STATUS_ENUM {
  NONE = "None",
  SCHEDULED = "Upcoming",
  CANCELLED = "Cancelled",
  IN_PROGRESS = "In Progress",
  COMPLETED = "Completed",
}

export const getStatusColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case "scheduled":
      return colors.primary.main;
    case "in_progress":
      return colors.warning.focus;
    case "missed":
      return colors.error.main;
    default:
      return colors.text.secondary;
  }
};

export const getBadgeColor = (status: string): string => {
  switch (status.toLowerCase()) {
    case "scheduled":
      return colors.secondary.main;
    case "in_progress":
      return colors.warning.main;
    default:
      return colors.secondary.main;
  }
};

const getAppointmentTypeLabels = (emr_name: string) => {
  return {
    [`${emr_name} Appointment`]: APPOINTMENT_TYPES.EMR,
    [`Adhoc - ${emr_name} patients`]: APPOINTMENT_TYPES.ADHOC_EMR,
    [`Adhoc - Non ${emr_name} patients`]: APPOINTMENT_TYPES.ADHOC,
  };
};

export const getAppointmentRows = (
  appointments: Appointment[],
  emr_name: string,
): AppointmentRow[] => {
  return appointments.map(
    ({
      appointment_name,
      appointment_time,
      appointment_type,
      id,
      reason_for_visit,
      appointment_status,
    }) => {
      return {
        id,
        patientName: appointment_name,
        date: extractDateFromTimestamp(appointment_time),
        time: extractTimeFromTimestamp(appointment_time),
        typeOfVisit: invertKeyValuePair(getAppointmentTypeLabels(emr_name))[appointment_type],
        reasonForVisit: reason_for_visit,
        details: (
          <RemoveRedEyeOutlined
            sx={{color: colors.text.tertiary + blur["80%"], height: "16px", width: "16px"}}
          />
        ),
        status: {
          text:
            (
              <Typography
                sx={{color: getStatusColor(appointment_status ?? ""), ...statusTextStyles()}}
                variant="h6">
                {
                  APPOINTMENT_STATUS_ENUM[
                    appointment_status as keyof typeof APPOINTMENT_STATUS_ENUM
                  ]
                }
                {(appointment_status === APPOINTMENT_STATUS_ENUM.IN_PROGRESS ||
                  appointment_status === APPOINTMENT_STATUS_ENUM.SCHEDULED) && (
                  <Box
                    sx={{
                      backgroundColor: getBadgeColor(appointment_status ?? ""),
                      ...recordBadgeStyles(),
                    }}>
                    <MicIcon color={getStatusColor(appointment_status ?? "")} />
                  </Box>
                )}
              </Typography>
            ) ?? "-",
          color: getStatusColor(appointment_status ?? ""),
        },
      };
    },
  );
};

export const getFilters = (emr_name: string): Filters => {
  return [
    {
      label: "Type of Visit",
      name: "appointment_type",
      options: Object.keys(getAppointmentTypeLabels(emr_name)).map(ele => {
        return {value: getAppointmentTypeLabels(emr_name)[ele], label: ele};
      }),
    },
    {
      label: "Status",
      name: "status",
      options: Object.keys(AppointmentStatus).map(ele => {
        return {
          value: AppointmentStatus[ele as keyof typeof AppointmentStatus],
          label: ele,
        };
      }),
    },
  ];
};

export const getColumns = (emr_name: string): GridColDef[] => [
  {
    field: "patientName",
    headerName: "Patient Name",
    minWidth: 150,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell>
        <Typography sx={getTypographyStyles("grey.700", 600)}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "date",
    headerName: "Date",
    minWidth: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography sx={getTypographyStyles("inherit")}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "time",
    headerName: "Time",
    minWidth: 130,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography sx={getTypographyStyles("inherit")}>{params.value}</Typography>
      </TrCell>
    ),
  },
  {
    field: "typeOfVisit",
    headerName: "Type of Visit",
    minWidth: 200,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell sx={getTrCellStyles()}>
        <Typography
          sx={getTypeOfVisitStyles(
            TypeOfVisitBackground[getAppointmentTypeLabels(emr_name)[params.value]],
          )}>
          {params.value}
        </Typography>
      </TrCell>
    ),
  },
  {
    field: "reasonForVisit",
    headerName: "Reason for Visit",
    sortable: false,
    minWidth: 160,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <TrCell
        sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        <Typography sx={getTypographyStyles("inherit")}>{params.value ?? "-"}</Typography>
      </TrCell>
    ),
  },
  {
    field: "details",
    headerName: "Details",
    sortable: false,
    headerAlign: "center",
    minWidth: 100,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => (
      <Box sx={{display: "flex", justifyContent: "center", alignItems: "center", height: "100%"}}>
        {params.value}
      </Box>
    ),
  },
  {
    field: "status",
    headerName: "Status",
    sortable: false,
    minWidth: 160,
    flex: 1,
    renderCell: (params: GridRenderCellParams<any>) => {
      const {text, color = "grey"} = params.value;
      return (
        <TrCell>
          <Typography sx={getTypographyStyles(color, 600)}>{text}</Typography>
        </TrCell>
      );
    },
  },
];
