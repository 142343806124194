import {Box} from "@mui/material";
import {DataGridProps, GridColDef} from "@mui/x-data-grid";
import {Dispatch, SetStateAction, useEffect, useState} from "react";
import MyModal from "../MyModal";
import MyCalendar from "../MyCalendar";
import Filter from "./Filter";
import colors from "../../../assets/theme/base/colors";
import {Filters} from "./Filter/types";
import TableHeader from "./TableHeader";
import {DataTableFilters, DataTableInitialState, SelectedPeriod} from "./types";
import {getStartDateBasedOnPeriod} from "./utils";
import {MultiSelectValues} from "../MultiSelect/types";
import {getContainerStyles, getFilterModalStyles, getModalSxStyles, MyDataGrid} from "./styles";
import {PAGE_SIZE_OPTIONS} from "./constants";
import NoResultsFound from "./NoResultsFound";
import blur from "../../../assets/theme/base/blur";

export default function MyDataTable<DataType, AppointmentRowType>({
  rows,
  columns,
  data,
  filters,
  dataTableFilters,
  setDataTableFilters,
  initialState,
  onUpdate,
  ...dataGridProps
}: {
  rows: AppointmentRowType;
  columns: GridColDef[];
  data: DataType[];
  filters: Filters;
  dataTableFilters: DataTableFilters;
  setDataTableFilters: Dispatch<SetStateAction<DataTableFilters>>;
  onUpdate: (dateStart: string, dateEnd: string, pageSize: number) => void;
  initialState: DataTableInitialState;
} & DataGridProps) {
  const [showCalendar, setShowCalendar] = useState(false);
  const [showFilter, setShowFilter] = useState(false);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_OPTIONS[0]);
  const [selectedPageNumber, setSelectedPageNumber] = useState(0);

  useEffect(() => {
    const {dateEnd, dateStart} = getStartDateBasedOnPeriod(
      dataTableFilters.selectedPeriod,
      dataTableFilters.selectedDateRange,
      showCalendar,
    );
    onUpdate(dateStart, dateEnd, pageSize);
  }, [showCalendar, dataTableFilters.selectedPeriod, dataTableFilters.selectedDateRange, pageSize]);

  const filterOnSuccess = (selectedFilters: MultiSelectValues) => {
    setDataTableFilters((prev: DataTableFilters) => {
      return {...prev, selectedFilters};
    });
    setShowFilter(false);
  };

  const filterOnAbort = () => {
    setDataTableFilters((prev: DataTableFilters) => {
      return {...prev, selectedFilters: {}};
    });
    setShowFilter(false);
  };

  const onCalendarSuccess = ({
    selectedDateRange,
  }: {
    selectedDateRange: {to: number; from: number} | undefined;
    selectedDate: string;
  }): void => {
    setDataTableFilters((prev: DataTableFilters) => {
      return {
        ...prev,
        selectedDateRange: {modified: true, range: selectedDateRange},
        selectedPeriod: SelectedPeriod.CUSTOM,
      };
    });
    setShowCalendar(false);
  };

  const onCalendarAbort = () => {
    setDataTableFilters((prev: DataTableFilters) => {
      return {
        ...prev,
        selectedDateRange: {...dataTableFilters.selectedDateRange, modified: false},
      };
    });
    setShowCalendar(false);
  };

  const handlePaginationChange = (paginationModel: {page: number; pageSize: number}) => {
    const {page, pageSize} = paginationModel;
    setSelectedPageNumber(page);
    setPageSize(pageSize);
    const offset = page * pageSize;
    setDataTableFilters((prev: DataTableFilters) => ({...prev, offset}));
  };

  useEffect(() => {
    const {dateEnd, dateStart} = getStartDateBasedOnPeriod(
      dataTableFilters.selectedPeriod,
      dataTableFilters.selectedDateRange,
      showCalendar,
    );
    onUpdate(dateStart, dateEnd, pageSize);
  }, [showCalendar, dataTableFilters.selectedPeriod, dataTableFilters.selectedDateRange]);

  return (
    <Box sx={getContainerStyles()}>
      <TableHeader<DataType>
        count={dataGridProps.rowCount ?? 0}
        data={data}
        setShowCalender={setShowCalendar}
        setShowFilter={setShowFilter}
        pageSize={pageSize}
        setPageSize={setPageSize}
        dataTableFilters={dataTableFilters}
        setDataTableFilters={setDataTableFilters}
        initialState={initialState}
      />
      <MyDataGrid
        onPaginationModelChange={(page: {page: number; pageSize: number}) => {
          handlePaginationChange(page);
        }}
        paginationMode="server"
        pagination
        rows={rows}
        columns={columns}
        paginationModel={{page: selectedPageNumber, pageSize: pageSize}}
        getRowHeight={() => "auto"}
        initialState={{
          pagination: {
            paginationModel: {page: 0, pageSize},
          },
        }}
        checkboxSelection={false}
        rowSelection={false}
        slots={{
          noRowsOverlay: NoResultsFound,
        }}
        {...dataGridProps}
      />
      {!!showCalendar && (
        <MyModal
          closeModal={() => setShowCalendar(false)}
          Component={
            <MyCalendar
              mode="range"
              showApplyCancelButtons={true}
              onAbort={onCalendarAbort}
              onSuccess={onCalendarSuccess}
            />
          }
          sx={getModalSxStyles()}
        />
      )}
      {!!showFilter && (
        <MyModal
          sx={getFilterModalStyles()}
          closeModal={() => setShowFilter(false)}
          Component={
            <Filter
              title="Filter records"
              description="Select fields that you want to see inside the table "
              filters={filters}
              initialFilters={dataTableFilters.selectedFilters}
              abortButtonName="Clear"
              successButtonName="Apply Filters"
              titleStyles={{fontSize: "16px", fontWeight: "bold"}}
              descriptionStyles={{color: colors.text.tertiary + blur["80%"]}}
              closeModal={() => setShowFilter(false)}
              onSuccess={filterOnSuccess}
              onAbort={filterOnAbort}
            />
          }
        />
      )}
    </Box>
  );
}
