import {CalendarMonthOutlined} from "@mui/icons-material";
import {Box, Typography} from "@mui/material";
import {extractDateFromTimestamp} from "../../../utils/DateUtils";
import colors from "../../../assets/theme/base/colors";
import borders from "../../../assets/theme/base/borders";

interface Props {
  miliseconds: number | undefined;
  placeholder: string;
}

function MyDateLabel({miliseconds, placeholder}: Props) {
  return (
    <Box
      aria-label={"Date Label"}
      sx={{
        display: "flex",
        alignItems: "center",
        background: colors.background.light,
        p: "5px",
        borderRadius: borders.borderRadius.md,
      }}>
      <Typography
        sx={{
          fontSize: "15px",
          color: miliseconds ? colors.text.tertiary : colors.dark.secondary,
          fontWeight: 500,
          mx: 1,
          my: 0.5,
        }}>
        {miliseconds ? extractDateFromTimestamp(new Date(miliseconds).toISOString()) : placeholder}
      </Typography>
      <CalendarMonthOutlined sx={{color: colors.grey[500], ml: 1, fontSize: "22px"}} />
    </Box>
  );
}

export default MyDateLabel;
