import {SxProps, Theme} from "@mui/system";
import borders from "../../../assets/theme/base/borders";
import colors from "../../../assets/theme/base/colors";

export function getDropdownNavStyles(): SxProps<Theme> {
  return {
    flex: 1,
    m: "1rem 0.5rem",
  };
}

export function getDropdownNavInnerStyles(): SxProps<Theme> {
  return {
    display: "flex",
    justifyContent: "space-between",
    flex: 1,
    mb: 2,
  };
}

export function getDateRangeBoxStyles(): SxProps<Theme> {
  return {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    mt: 0.5,
  };
}

export function getTypographyStyles(): SxProps<Theme> {
  return {
    fontSize: "16px",
    color: colors.grey[600],
    fontWeight: 500,
    mx: 1.5,
  };
}

export function getMonthGridStyles(): SxProps<Theme> {
  return {
    mt: 9,
  };
}

export function getButtonSx() {
  return {
    success: {borderRadius: borders.borderRadius.lg},
    abort: {borderRadius: borders.borderRadius.lg},
  };
}
